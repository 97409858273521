import { React, componentDidMount } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Provider } from "react-redux";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { blue800, amber50 } from "material-ui/styles/colors";
import { CssBaseline } from "@material-ui/core";
import { Amplify } from "aws-amplify";
import App from "./App";
import { store } from "./store";

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: amber50,
  },
  tabs: {
    backgroundColor: blue800,
  },
  // components: {
  //   MuiButtonBase: {
  //     defaultProps: {
  //       disableRipple: true,
  //       disableElevation: true,
  //     },
  //   },
  // },
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
  },
});

ReactDOM.render(
  <MuiThemeProvider muiTheme={muiTheme}>
    <div className="app">
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </div>
  </MuiThemeProvider>,
  document.getElementById("root")
);
