export const FLIGHT_STATES = {
  ACTIVATED: "Activated",
  ACCEPTED: "Accepted",
  ENDED: "Ended",
  CONTINGENT: "Contingent",
  NON_CONFORMANT: "Nonconforming",
  FOCUSSED: "Focussed",
};
export const HIGHLIGHT_COLORS = {
  gray: [105, 105, 105, 0.2],
  blue: [0, 0, 255, 0.1],
  orange: [200, 150, 79, 0.1],
  brightOrange: "#EE9F18",
  green: [82, 208, 23, 0.1],
  red: [255, 50, 33, 0.1],
  white: [255, 255, 255, 0.1],
  grey2: [206, 206, 206, 0.1],
  yellow: [255, 255, 0, 0.5],
};

export const HIGHLIGHT_COLORS_DARKER = {
  gray: [200, 200, 200, 0.2],
  blue: [0, 0, 255, 0.1],
  orange: [100, 80, 40, 0.1],
  brightOrange: "#EE9F18",
  green: [40, 95, 12, 0.1],
  red: [120, 20, 13, 0.1],
  white: [255, 255, 255, 0.1],
  grey2: [206, 206, 206, 0.1],
};

export const HIGHLIGHT_COLORS_CONFORMANCE = {
  gray: [105, 105, 105, 0.2],
  blue: [0, 0, 255, 0.1],
  orange: [255, 116, 56, 0.2],
  green: [82, 208, 23, 0.1],
  red: [246, 34, 23, 0.1],
  white: [255, 255, 255, 0.1],
  grey2: [206, 206, 206, 0.1],
};
export const CONSTRAINT_STATES = {
  ACTIVATED: "Activated",
  INACTIVATED: "Inactivated",
  FOCUSSED: "Focussed",
  CONDITIONAL: "Conditional",
};
