import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../store/actions";
import { store } from "../store";

const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const useCognitoAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const envVar = useSelector((state) => state.envVar);

  const logout = () => {
    const params = new URLSearchParams();
    localStorage.clear();
    dispatch(
      setAuth({
        accessToken: "",
        idObject: null,
        isPilot: false,
        isOperator: false,
        isAuthority: false,
        isRemoteId: false,
        isLoggedIn: false,
      })
    );

    const cognitoDomain = envVar["cognito_domain-non_authority"]
      ? envVar["cognito_domain-non_authority"]?.Value
      : "";
    const clientId = process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID;
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
  };

  return {
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
    isLoggedIn: auth.isLoggedIn,
    username: auth.userInfo?.["cognito:username"],
    isPilot: auth.isPilot,
    isOperator: auth.isOperator,
    isAuthority: auth.isAuthority,
    isRemoteId: auth.isRemoteId,
    logout,
  };
};

export default useCognitoAuth;
