import { differenceInMinutes } from "date-fns";
import { Marker, Popup } from "mapbox-gl";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import circle from "@turf/circle";
import {
  CONSTRAINT_STATES,
  FLIGHT_STATES,
  HIGHLIGHT_COLORS,
  HIGHLIGHT_COLORS_DARKER,
  HIGHLIGHT_COLORS_CONFORMANCE,
} from "../../config/flightStatusColor";
import getStaticConstraints from "./getStaticConstraints";
import { getGnsss } from "./gnss/gnss";
import { ModelLayer } from "./model-layer";

import { getLatestRainMapURL } from "./rainmap/rainMapUtils";
import { getCurrentRoundedTime } from "./utils/floorTimeTo5Mins";
// import { getAirmapspace } from "./airmap/airmapspace";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import { getConvert } from "../../services/convertVertices";
import {
  generateMarker,
  generatePopupContent,
  popupOffsets,
} from "./windmarker/generators";
import { fetchWindData } from "./windmarker/windUtils";
import { store } from "../../store";

const sg_anchorage = require("../../lib/arcgis/Anchorage-SG-geojson.geojson");
const sg_drone_port = require("../../lib/arcgis/Drone-Port-geojson.geojson");
const sg_boundary_merged = require("../../lib/arcgis/Boundary-merged-SG-geojson.geojson");
const sg_population_density = require("../../lib/arcgis/SG_Population_Density-geojson.geojson");
const okinawa_precipitation = require("../../lib/arcgis/okinawa_precipiration_polygon.geojson");
const japan_did = require("../../lib/arcgis/Japan_DID_Areas.geojson");
const japan_ctr = require("../../lib/arcgis/Japan_CTR_Areas.geojson");

/**
 * Converts angle from degrees to radians
 * @param {Number} degrees Angle in degrees
 * @returns Angle in radians
 */
function degToRad(degrees) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}

/**
 * Retrieves color to display for Flight state
 * @param {Number} state Flight state
 * @returns Color for flight state
 */
function getFlightStateColor(state) {
  switch (state) {
    case FLIGHT_STATES.ACTIVATED: {
      return `rgb(${HIGHLIGHT_COLORS.green.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.CONTINGENT: {
      return `rgb(${HIGHLIGHT_COLORS.red.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.NON_CONFORMANT: {
      return `rgb(${HIGHLIGHT_COLORS.orange.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.FOCUSSED: {
      return `rgb(${HIGHLIGHT_COLORS.blue.slice(0, 3).join(",")})`;
    }
    default:
      return "slategrey";
  }
}

function getDarkerColor(state) {
  switch (state) {
    case FLIGHT_STATES.ACTIVATED: {
      return `rgb(${HIGHLIGHT_COLORS_DARKER.green.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.CONTINGENT: {
      return `rgb(${HIGHLIGHT_COLORS_DARKER.red.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.NON_CONFORMANT: {
      return `rgb(${HIGHLIGHT_COLORS_DARKER.orange.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.FOCUSSED: {
      return `rgb(${HIGHLIGHT_COLORS_DARKER.blue.slice(0, 3).join(",")})`;
    }
    default:
      return `rgb(${HIGHLIGHT_COLORS_DARKER.gray.slice(0, 3).join(",")})`;
  }
}

/**
 * Initialises and loads 3D models for use on Mapbox
 * @param {*} modelMap Storage for 3D objects
 */
export async function load3dModels(modelMap) {
  if (modelMap.has("shipModel")) {
    return;
  }

  // const collisionGeometry = new THREE.PlaneGeometry(1, 1);
  const collisionGeometry = new THREE.BoxGeometry(1, 1, 1);
  const collisionMaterial = new THREE.MeshToonMaterial({
    color: 0x860111,
    transparent: true,
  });

  modelMap.set(
    "collisionModel",
    new THREE.Mesh(collisionGeometry, collisionMaterial)
  );

  const shipGeometry = new THREE.BoxGeometry(1, 1, 1);
  const shipMaterial = new THREE.MeshBasicMaterial({ color: 0x860111 });
  modelMap.set("shipModel", new THREE.Mesh(shipGeometry, shipMaterial));

  const planeGltf = await new GLTFLoader().loadAsync("/airplanev3.glb");
  const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x860111 });
  planeGltf.scene.traverse((o) => {
    // eslint-disable-next-line no-param-reassign
    if (o.isMesh) o.material = planeMaterial;
  });
  modelMap.set("planeModel", planeGltf.scene);

  const arrowGltf = await new GLTFLoader().loadAsync("/arrow.glb");
  // eslint-disable-next-line guard-for-in
  for (const s in FLIGHT_STATES) {
    const state = FLIGHT_STATES[s];
    const arrowMaterial = new THREE.MeshBasicMaterial({
      color: getFlightStateColor(state),
    });
    const arrowMaterialLightMode = new THREE.MeshPhysicalMaterial({
      color: getFlightStateColor(state),
      roughness: 0.1,
      metalness: 0.5,
    });
    const arrow = arrowGltf.scene.clone();
    arrow.traverse((o) => {
      // eslint-disable-next-line no-param-reassign
      if (o.isMesh) o.material = arrowMaterial;
    });
    modelMap.set(`${state}ArrowModel`, arrow);
    const arrowLightMode = arrowGltf.scene.clone();
    arrowLightMode.traverse((o) => {
      // eslint-disable-next-line no-param-reassign
      if (o.isMesh) o.material = arrowMaterialLightMode;
    });
    modelMap.set(`${state}ArrowModelLightMode`, arrowLightMode);
  }
}

/**
 * Adds data for population density into Mapbox map
 * @param {*} map Mapbox map
 */
export function addSgPopulationDensitySource(map) {
  if (map.getSource("sg-population-density-coords")) return;
  map.addSource("sg-population-density-coords", {
    type: "geojson",
    data: sg_population_density,
  });
}
export function addSgPopulationDensityLayer(map, showSgPopulationLayer) {
  if (map.getLayer("sg-population-density-layer")) {
    setLayerVisibility(
      map,
      "sg-population-density-layer",
      showSgPopulationLayer
    );
    return;
  }
  // adds a outlined boundary layer
  map.addLayer({
    id: "sg-population-density-layer",
    type: "fill",
    source: "sg-population-density-coords",
    paint: {
      "fill-color": [
        "interpolate",
        ["linear"],
        ["get", "dynamic_population"], // GeoJSON property to use for color
        41000,
        "#74c476",
        247000,
        "#fee08b",
        638000,
        "#fc8d59",
      ],
      "fill-opacity": 0.7,
    },
  });
  setLayerVisibility(map, "sg-population-density-layer", showSgPopulationLayer);
}
/**
 * Adds data for sg boundary into Mapbox map
 * @param {*} map Mapbox map
 */
export function addSgBoundarySources(map) {
  if (map.getSource("sg-boundary-coords")) return;
  map.addSource("sg-boundary-coords", {
    type: "geojson",
    data: sg_boundary_merged,
  });
}
/**
 * Adds layer displaying SG Boundary into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showStaticConstraintsLayer Indicates whether static constraints layer should be shown
 */
export function addSgBoundaryLayers(map, showSgBoundaryLayer) {
  if (map.getLayer("sg-boundary-layer")) {
    setLayerVisibility(map, "sg-boundary-layer", showSgBoundaryLayer);
    return;
  }
  // adds a outlined boundary layer
  map.addLayer({
    id: "sg-boundary-layer",
    type: "line",
    source: "sg-boundary-coords",
    paint: {
      "line-color": "#FFFFFF",
      "line-width": 2,
    },
  });
  // // adds a fill to the boundary layer
  // map.addLayer({
  //   id: "sg-boundary-layer",
  //   type: "fill",
  //   source: "sg-boundary-coords",
  //   paint: {
  //     "fill-color": "#0080ff", // blue color fill
  //     "fill-opacity": 0.2,
  //     "fill-outline-color": "#0080ff",
  //   },
  // });
  setLayerVisibility(map, "sg-boundary-layer", showSgBoundaryLayer);
}

/**
 * Adds data for no fly zones (static constraints) into Mapbox map
 * @param {*} map Mapbox map
 */
export async function addNoFlySources(map) {
  if (map.getSource("no-fly-coords")) return;
  map.addSource("no-fly-coords", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  const constraints = await getStaticConstraints();
  map.getSource("no-fly-coords").setData({
    type: "FeatureCollection",
    features: constraints,
  });
}

/**
 * Adds layer displaying no fly zones (static constraints) into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showStaticConstraintsLayer Indicates whether static constraints layer should be shown
 */
export function addNoFlyLayers(map, showStaticConstraintsLayer) {
  if (map.getLayer("no-fly")) return;
  map.addLayer({
    id: "no-fly",
    type: "fill-extrusion",
    source: "no-fly-coords",
    paint: {
      "fill-extrusion-color": ["get", "color"],
      "fill-extrusion-height": ["get", "height"],
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 0,
    },
  });
  map.addLayer({
    id: "no-fly-outline",
    type: "fill",
    source: "no-fly-coords",
    paint: {
      "fill-color": "rgba(0,0,0,0)",
      "fill-outline-color": "#000000",
    },
  });
  setLayerVisibility(map, "no-fly", showStaticConstraintsLayer);
  setLayerVisibility(map, "no-fly-outline", showStaticConstraintsLayer);
}

/**
 * Adds data for SG anchorage areas into Mapbox map
 * @param {*} map Mapbox map
 */
export function addAnchorageSources(map) {
  if (map.getSource("sg-anchorage")) return;
  map.addSource("sg-anchorage", {
    type: "geojson",
    data: sg_anchorage,
  });
}

/**
 * Adds layer displaying SG anchorage areas into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showAnchorageLayer Indicates whether layer should be shown
 */
export function addAnchorageLayers(map, showAnchorageLayer) {
  if (map.getLayer("sg-anchor")) return;
  map.addLayer({
    id: "sg-anchor",
    type: "fill",
    source: "sg-anchorage",
    paint: {
      "fill-color": "#0080ff", // blue color fill
      "fill-opacity": 0.2,
    },
  });
  map.addLayer({
    id: "sg-anchor-outline",
    type: "line",
    source: "sg-anchorage",
    paint: {
      "line-color": "#0080ff",
      "line-width": 2,
    },
  });
  setLayerVisibility(map, "sg-anchor", showAnchorageLayer);
  setLayerVisibility(map, "sg-anchor-outline", showAnchorageLayer);
}

/**
 * Adds data for drone ports into Mapbox map
 * @param {*} map Mapbox map
 */
export function addDronePortSources(map) {
  if (map.getSource("sg-drone-port")) return;
  map.addSource("sg-drone-port", {
    type: "geojson",
    data: sg_drone_port,
  });
}

export function addDronePortLayers(map, showDronePortLayer) {
  if (!map.getLayer("sg-port")) {
    map.addLayer({
      id: "sg-port",
      type: "fill",
      source: "sg-drone-port",
      paint: {
        "fill-color": "#EED916", // blue color fill
        "fill-opacity": 0.2,
      },
    });
    map.addLayer({
      id: "sg-port-outline",
      type: "line",
      source: "sg-drone-port",
      paint: {
        "line-color": "#EED916",
        "line-width": 1,
      },
    });
  }
  setLayerVisibility(map, "sg-port", showDronePortLayer);
  setLayerVisibility(map, "sg-port-outline", showDronePortLayer);
}

/**
 * Color Function for AirMap
 */
function typeToColorAirMapSpace(type) {
  switch (type) {
    case "airport":
    case "heliport":
      return "#FFBE69";
    case "wildlife":
    case "park":
      return "#7AEE9A";
    case "power_plant":
    case "prison":
    case "school":
    case "hospital":
      return "#3461C7";
    case "controlled_airspace":
      return "#D773FF";
    case "special_use_airspace":
      return "#FF0037";
    case "tfr":
      return "#FA82BE";
    default:
      console.warn(`unexpected type: ${type}`);
      return "#FF77FF";
  }
}

/**
 * Adds data for Air Space into Mapbox map
 * @param {*} map Mapbox map
 */
export function getAirSpaceFetaures(airMapSpaceData) {
  if (!airMapSpaceData || airMapSpaceData === undefined) return [];
  return airMapSpaceData?.flatMap((c) => {
    const color = typeToColorAirMapSpace(c.type);
    const datas = {
      type: "Feature",
      id: c.id,
      latitude: c.latitude,
      longitude: c.longitude,
      min_circle_radius: c.min_circle_radius,
      name: c.name,
      types: c.type,
      country: c.country,
      state: c.state,
      city: c.city,
      last_updated: c.last_updated,
      airspace_uuid: c.airspace_uuid,
      properties: {
        color,
        lowest_limit: c.properties.lowest_limit,
        name: c.name,
        types: c.type,
        country: c.country,
        state: c.state,
        city: c.city,
      },
      geometry: {
        coordinates: c.geometry.coordinates,
        type: c.geometry.type,
      },
      ruleset_id: c.ruleset_id,
    };
    return datas;
  });
  // return airMapSpaceData;
}

/**
 * Adds layer displaying airspace into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} Indicates
 */

export async function addAirSpaceSource(map, airMapSpaceData) {
  if (map.getSource("airmap-space-coords")) return;
  map.addSource("airmap-space-coords", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: getAirSpaceFetaures(airMapSpaceData),
    },
  });
}

/**
 * Adds layer displaying airspace into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} Indicates whether dynamic constraints should be shown
 */
export async function addAirSpaceLayers(map, showConstraintsLayer) {
  if (!map.getLayer("airmap-space-fill")) {
    // map.addLayer({
    //   id: "airmap-space-fill",
    //   type: "fill",
    //   source: "airmap-space-coords",
    //   paint: {
    //     "fill-color": ["get", "color"],
    //     "fill-outline-color": "#000000",
    //     "fill-opacity": 0.2,
    //   },
    // });

    const openAipApiKey = store.getState().envVar["api_key-openaip"].Value;

    map.addLayer({
      id: "airmap-space-fill",
      type: "raster",
      source: {
        type: "raster",
        tiles: [
          `https://api.tiles.openaip.net/api/data/openaip/{z}/{x}/{y}.png?apiKey=${openAipApiKey}`,
        ],
        tileSize: 256,
      },
      paint: {
        "raster-contrast": 0.7,
      },
      minzoom: 0,
      maxzoom: 22,
    });
  }
  setLayerVisibility(map, "airmap-space-fill", showConstraintsLayer);
}

/**
 * Retrieves color to display for Constraint state
 * @param {string} state Constraint state
 * @returns Color for Constraint state
 */
function getConstraintStateColor(state) {
  switch (state) {
    case CONSTRAINT_STATES.ACTIVATED: {
      return `rgb(${HIGHLIGHT_COLORS.red.slice(0, 3).join(",")})`;
    }
    case CONSTRAINT_STATES.INACTIVATED: {
      return `rgb(${HIGHLIGHT_COLORS.gray.slice(0, 3).join(",")})`;
    }
    case CONSTRAINT_STATES.FOCUSSED: {
      return `rgb(${HIGHLIGHT_COLORS.blue.slice(0, 3).join(",")})`;
    }
    case CONSTRAINT_STATES.CONDITIONAL: {
      return `rgb(${HIGHLIGHT_COLORS.yellow.slice(0, 3).join(",")})`;
    }
    default:
      return "white";
  }
}

/**
 * Parses constraints into GEOJson format
 * @param {*} constraints Constraints to parse
 * @returns Constraints in GEOJson format
 */
export function getConstraintsFeatures(constraints, focussedOperation) {
  if (!constraints) return [];
  return constraints.flatMap((c) => {
    const currentTime = new Date();
    const { extents } = c;
    const timeStart = new Date(extents[0].time_start.value).getTime();
    let constraintStatus = "";
    if (timeStart <= currentTime.getTime()) {
      constraintStatus = "Activated";
    } else {
      constraintStatus = "Inactivated";
    }
    if (c?.rule?.conditional) {
      constraintStatus = "Conditional";
    }
    if (
      focussedOperation &&
      c.constraint_id === focussedOperation.reference.id
    ) {
      constraintStatus = FLIGHT_STATES.FOCUSSED;
    }
    const color = getConstraintStateColor(constraintStatus);
    return c.extents.map((e) => ({
      type: "Feature",
      properties: {
        color,
        height: e.volume.altitude_upper.value - e.volume.altitude_lower.value,
        base: 0,
        altitudeHigher: e.volume.altitude_upper.value,
        altitudeLower: e.volume.altitude_lower.value,
        timeStart: convertZuluToLocalTime(e.time_start.value),
        timeEnd: convertZuluToLocalTime(e.time_end.value),
        constraintID: c.constraint_id,
        name: c.name,
        desc: c.description,
        rule: c.rule,
        prohibitWhitelist: c.prohibited_conditions?.whitelist,
        authWhitelist: c.authorisation_conditions?.whitelist,
        authReq: c.authorisation_conditions,
        prohibitReq: c.prohibited_conditions,
        conditions: c.conditional_conditions
          ? c.conditional_conditions
          : c?.conditions,
        ...("recurring" in c && { recurring: c.recurring }),
        ...("recurrence_range" in c && { recurrenceRange: c.recurrence_range }),
      },
      geometry: e.volume.outline_polygon,
    }));
  });
}

/**
 * Adds data for dynamic constraints into Mapbox map
 * @param {*} map Mapbox map
 * @param {*} constraints Constraints to add
 */
export function addConstraintSource(map, constraints) {
  if (map.getSource("constraints-coords")) return;
  map.addSource("constraints-coords", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: getConstraintsFeatures(constraints),
    },
  });
}

/**
 * Adds layer displaying dynamic constraints into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showConstraintsLayer Indicates whether dynamic constraints should be shown
 */
export function addConstraintsLayers(map, showConstraintsLayer) {
  if (map.getLayer("constraints")) return;
  map.addLayer({
    id: "constraints",
    type: "fill-extrusion",
    source: "constraints-coords",
    paint: {
      "fill-extrusion-color": ["get", "color"],
      "fill-extrusion-height": ["get", "height"],
      "fill-extrusion-base": ["get", "base"],
      "fill-extrusion-opacity": 0.5,
    },
  });
  setLayerVisibility(map, "constraints", showConstraintsLayer);
}

/**
 * Retrieves color to display for conformance state
 * @param {string} state conformance state
 * @returns Color for conformance state
 */
function getConformanceStateColor(state) {
  switch (state) {
    case FLIGHT_STATES.ACTIVATED: {
      return `rgb(${HIGHLIGHT_COLORS_CONFORMANCE.green.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.CONTINGENT: {
      return `rgb(${HIGHLIGHT_COLORS_CONFORMANCE.red.slice(0, 3).join(",")})`;
    }
    case FLIGHT_STATES.NON_CONFORMANT: {
      return `rgb(${HIGHLIGHT_COLORS_CONFORMANCE.orange
        .slice(0, 3)
        .join(",")})`;
    }
    case FLIGHT_STATES.FOCUSSED: {
      return `rgb(${HIGHLIGHT_COLORS_CONFORMANCE.blue.slice(0, 3).join(",")})`;
    }
    default:
      return "slategrey";
  }
}
/**
 * Parses conformance into GEOJson format
 * @param {*} conformanceData conformance to parse
 * @returns conformance in GEOJson format
 */
export function getConformanceFeatures(conformanceData) {
  if (!conformanceData && conformanceData === undefined) return [];
  return conformanceData?.flatMap((c) => {
    if (c?.dataConformance?.state === "Activated") return [];
    const { volume } = c;
    const convertCordinate =
      c?.dataConformance?.volume?.volume?.outline_polygon?.vertices;
    const conformanceStatus = c.dataConformance?.state;
    const color = getConformanceStateColor(conformanceStatus);
    return {
      type: "Feature",
      properties: {
        color,
        height: c?.dataConformance?.volume?.volume?.altitude_upper?.value,
        base: c?.dataConformance?.volume?.volume?.altitude_lower?.value,
        timeStart: convertZuluToLocalTime(
          c?.dataConformance?.volume?.time_start?.value
        ),
        timeEnd: convertZuluToLocalTime(
          c?.dataConformance?.volume?.time_end?.value
        ),
        conformanceID: convertZuluToLocalTime(
          c?.dataConformance?.volume?.time_start?.value
        ),
      },
      geometry: {
        coordinates: [getConvert(convertCordinate)],
        type: "Polygon",
      },
    };
  });
}

/**
 * Adds data for dynamic conformance into Mapbox map
 * @param {*} map Mapbox map
 * @param {*} conformanceData conformance to add
 */
export function addConformanceSource(map, conformanceData) {
  if (map.getSource("conformances-coords")) return;
  map.addSource("conformances-coords", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
}
export function addConformanceSourceLine(map, conformanceData) {
  if (map.getSource("conformancesLinessource")) return;
  let convertCordinate = [];
  let conformanceStatus = "";
  let color = [];
  if (!conformanceData && conformanceData === undefined) {
    convertCordinate =
      conformanceData?.volume?.volume?.outline_polygon?.vertices;
    conformanceStatus = conformanceData.state;
    color = getConformanceStateColor(conformanceStatus);
  }
  map.addSource("conformancesLinessource", {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {
        color,
      },
      geometry: {
        type: "LineString",
        coordinates: [getConvert(convertCordinate)],
      },
    },
  });
}
/**
 * Adds layer displaying dynamic conformance into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showConformanceLayer Indicates whether dynamic conformance should be shown
 */
export function addConformanceLayers(map, showConformanceLayer) {
  if (map.getLayer("conformances")) return;
  map.addLayer({
    id: "conformances",
    type: "fill-extrusion",
    source: "conformances-coords",
    paint: {
      "fill-extrusion-color": ["get", "color"],
      "fill-extrusion-height": ["get", "height"],
      "fill-extrusion-base": ["get", "base"],
      "fill-extrusion-opacity": 0.3,
    },
  });
  // setLayerVisibility(map, "conformances", showConformanceLayer);
}
export function addConformanceLayersLines(map) {
  if (map.getLayer("conformancesLines")) return;
  map.addLayer({
    id: "conformancesLines",
    type: "line",
    source: "conformancesLinessource",
    layout: { "line-join": "round", "line-cap": "round" },
    paint: {
      "line-color": "#0080ff",
      "line-width": 6,
    },
  });
}
/**
 * Adds layer displaying buildings into Mapbox map
 * @param {*} map Mapbox map
 */
export function addBuildingLayer(map) {
  if (map.getLayer("add-3d-buildings")) return;
  const { layers } = map.getStyle();
  const labelLayerId = layers.find(
    (layer) => layer.type === "symbol" && layer.layout["text-field"]
  ).id;
  map.addLayer(
    {
      id: "add-3d-buildings",
      source: "composite",
      "source-layer": "building",
      filter: ["==", "extrude", "true"],
      type: "fill-extrusion",
      minzoom: 15,
      paint: {
        "fill-extrusion-color": "#aaa",
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "height"],
        ],
        "fill-extrusion-base": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "min_height"],
        ],
        "fill-extrusion-opacity": 0.6,
      },
    },
    labelLayerId
  );
}

// collision convert
function convertCollisionData(overlappingCells, modelMap, isTerrainEnabled) {
  if (!modelMap.has("collisionModel")) {
    return [];
  }
  const overlappingCellsData = [];
  overlappingCells.forEach((cell) => {
    overlappingCellsData.push({
      model: modelMap.get("collisionModel"),
      fixedSize: false,
      scale: [cell.length, cell.length, 0.1],
      rotate: [0, 0, 0],
      position: {
        lng: cell.lng,
        lat: cell.lat,
      },
      altitude: isTerrainEnabled ? cell.alt : 0,
      details: {
        lng: cell.lng,
        lat: cell.lat,
        alt: cell.alt,
      },
    });
  });
  return overlappingCellsData;
}

// collision add
export function addCollisionLayer(map, showCollisionLayer) {
  if (map.getLayer("collision-layer")) {
    setLayerVisibility(map, "collision-layer", showCollisionLayer);
    return;
  }
  const collisionLayer = new ModelLayer(
    "collision-layer",
    convertCollisionData
  );
  map.addLayer(collisionLayer);
}

/**
 * Parses AIS data into GEOJson format
 * @param {*} aisData AIS data to be parsed
 * @param {*} modelMap Storage for 3D models
 */
async function convertAISData(aisData, modelMap) {
  if (!Array.isArray(aisData) || !modelMap.has("shipModel")) return [];
  return aisData.map((s) => ({
    model: modelMap.get("shipModel"),
    fixedSize: false,
    scale: [
      s.vesselLength,
      s.vesselBreadth ? s.vesselBreadth : s.vesselLength * 0.1,
      0.1 * s.vesselLength,
    ],
    rotate: [0, 0, degToRad(90 + s.heading)],
    position: { lng: s.longitudeDegrees, lat: s.latitudeDegrees },
    altitude: 0.06 * s.vesselLength,
    details: {
      lng: `${s.longitudeDegrees}`,
      lat: `${s.latitudeDegrees}`,
      callSign: `${s.callSign}`,
      vesselName: `${s.vesselName}`,
      vesselLength: `${s.vesselLength}M`,
      vesselBreadth: `${s.vesselBreadth ? s.vesselBreadth : "N/A"}M`,
    },
  }));
}

/**
 * Adds layer displaying AIS data into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showAisLayer Indicates whether AIS layer should be shown
 * @param {*} aisData AIS data to show
 * @param {*} modelMap Storage for 3D models
 */
export function addAISLayer(map, showAisLayer, aisData, modelMap) {
  if (map.getLayer("ais-layer")) return;
  const aisLayer = new ModelLayer("ais-layer", convertAISData);
  map.addLayer(aisLayer);
  aisLayer.setData(aisData, modelMap);
  setLayerVisibility(map, "ais-layer", showAisLayer);
}

/**
 * Parses paid ADSB data into GEOJson format
 * @param {*} adsbData ADSB data to parse
 * @param {*} modelMap Storage for 3D models
 */
async function convertAdsbDataPaid(adsbData, modelMap) {
  if (!Array.isArray(adsbData) || !modelMap.has("planeModel")) return [];
  return adsbData.map((a) => {
    const displayAltitude = a.alt ? parseFloat(a.alt) / 3.281 : 0; // convert to meters
    const heading = a.heading ? parseFloat(a.heading) : 0;
    const gs = a.gs ? a.gs : "N/A";
    const callsign = a.ident ? a.ident : "N/A";

    return {
      model: modelMap.get("planeModel"),
      fixedSize: true,
      maxZoomScale: 0.1,
      scale: [0.000007, 0.000007, 0.000007],
      rotate: [degToRad(90), degToRad(-180 + heading), 0],
      position: { lng: parseFloat(a.lon), lat: parseFloat(a.lat) },
      altitude: a.alt ? parseFloat(a.alt) / 3.281 : 3,
      details: {
        alt: `${displayAltitude}M`,
        heading: `${heading} DEG(S)`,
        gs: `${gs}KT(S)`,
        callsign,
      },
    };
  });
}

/**
 * Parses free ADSB data into GEOJson format
 * @param {*} adsbData ADSB data to parse
 * @param {*} modelMap Storage for 3D models
 */
async function convertAdsbDataFree(adsbData, modelMap) {
  if (!Array.isArray(adsbData) || !modelMap.has("planeModel")) return [];
  return adsbData.map((a) => {
    // .filter((a) => a.details.geo_altitude.value <= 10000 / 3.281) // filter above 10000 feet
    const coordinates = a.details.position.coordinates
      ? a.details.position.coordinates
      : [0, 0];
    const lng = parseFloat(coordinates[0]);
    const lat = parseFloat(coordinates[1]);
    const heading = a.details.heading ? parseFloat(a.details.heading.value) : 0;
    const altitude = a.details.geo_altitude ? a.details.geo_altitude.value : 3;
    const displayAltitude = a.details.geo_altitude
      ? Math.round((a.details.geo_altitude.value / 3.281) * 100) / 100 // convert to meters, round to 2dp
      : 0;
    const gs = a.details.ground_speed
      ? a.details.ground_speed.value * 1.94384
      : "N/A"; // convert to knots
    const callsign = a.reference.callsign ? a.reference.callsign : "N/A";
    return {
      model: modelMap.get("planeModel"),
      fixedSize: true,
      maxZoomScale: 0.1,
      scale: [0.000007, 0.000007, 0.000007],
      rotate: [degToRad(90), degToRad(-180 + heading), 0],
      position: { lng, lat },
      altitude,
      details: {
        alt: `${displayAltitude}M`,
        heading: `${heading} DEG(S)`,
        gs: `${Math.round(gs * 100) / 100}KT(S)`, // round to 1dp
        callsign,
      },
    };
  });
}

/**
 * Adds layer displaying ADSB data into Mapbox map
 * @param {*} map Mapbox map
 * @param {Boolean} showAdsbLayer Indicates whether ADSB layer should be shown
 * @param {*} adsbData ADSB data to show
 * @param {*} modelMap Storage for 3D models
 * @param {Boolean} isAdsbPaid Indicates whether ADSB source is paid or free
 * @returns
 */
export function addAdsbLayer(
  map,
  showAdsbLayer,
  adsbData,
  modelMap,
  isAdsbPaid
) {
  if (map.getLayer("adsb-layer")) return;
  let adsbLayer;
  if (isAdsbPaid) {
    adsbLayer = new ModelLayer("adsb-layer", convertAdsbDataPaid);
  } else {
    adsbLayer = new ModelLayer("adsb-layer", convertAdsbDataFree);
  }
  map.addLayer(adsbLayer);
  adsbLayer.setData(adsbData, modelMap, map);
  setLayerVisibility(map, "adsb-layer", showAdsbLayer);
}

// // test ops volume with three
// function convertOperationData(
//   flightData,
//   trackerStatusMap,
//   trackerOpsMap,
//   focussedOperation,
//   isRemoteId,
//   map
// ) {
//   const model = modelMap.get(`${status}ArrowModel`);
//   return {
//     model,
//     fixedSize: true,
//     maxZoomScale: 0.15,
//     scale: [0.0000013, 0.0000013, 0.0000013],
//     position: {
//       lng: 2.2068032856657567, // below bcn
//       lat: 41.768591804739856,
//     },
//     altitude: 1000,
//     details: {
//       // speed: t[1]?.speed.toFixed(1),
//       // heading: t[1]?.track,
//       altitude: t[1]?.position.alt.toFixed(1),
//       intent: opsVolume.reference?.description,
//       opsVolume,
//       platformData: t[2][0]?.data,
//       trackerData: t[3][0]?.data,
//       position: {
//         lng: positionLng.toFixed(7), // 7dp for cm acc (approx only valid near equator)
//         lat: positionLat.toFixed(7),
//       },
//     },
//   };
// }

/**
 * Parses operation data into GEOJson format
 * @param {*} flightData Operation data to be parsed
 * @param {*} trackerStatusMap Mapping between operation status and tracker ID
 * @returns Parsed operation data
 * triggers every second due to interval set
 */
export function getOperationFeatures(
  flightData,
  trackerStatusMap,
  trackerOpsMap,
  focussedOperation,
  isRemoteId,
  map
) {
  // const operationVolumeLayer = new ModelLayer(
  //   "operation-volume-three",
  //   convertOperationData
  // );
  // map.addLayer(operationVolumeLayer);

  if (map.getTerrain() === null) {
    return flightData.flatMap((f) => {
      const { details, request } = f;
      let { state } = details;
      if (
        focussedOperation &&
        f.reference.id === focussedOperation.reference.id
      ) {
        state = FLIGHT_STATES.FOCUSSED;
        trackerStatusMap.set(f.reference.id, FLIGHT_STATES.FOCUSSED);
      }
      const airSpaceOptimised = request.airspace_optimised;
      const darkerColor = getDarkerColor(state);
      const color = getFlightStateColor(state);

      trackerStatusMap.set(f.reference.id, state);
      trackerOpsMap.set(f.details.platform_tracker_pairs[0].tracker_sn, state);
      // if (isRemoteId) {
      //   trackerStatusMap.set(
      //     f.details.platform_tracker_pairs[0].tracker_sn,
      //     state
      //   );
      //   trackerOpsMap.set(f.details.platform_tracker_pairs[0].tracker_sn, f);
      // } else {
      //   trackerStatusMap.set(f.reference.id, state);
      //   trackerOpsMap.set(f.reference.id, f);
      // }
      // const startTime =
      //   f.interuss.operational_intent_reference.time_start.value;
      // const endTime = f.interuss.operational_intent_reference.time_end.value;
      const operationJsonElevation = request.elevation;
      const output = details.operation_volumes.map((v, index) => {
        const { volume, time_start, time_end } = v;
        let volumeColor = color;
        if (airSpaceOptimised) {
          if (index % 2 === 0) {
            volumeColor = darkerColor;
          } else {
            volumeColor = color;
          }
        }
        return {
          type: "Feature",
          properties: {
            id: f.reference.id,
            color: volumeColor,
            height: volume.altitude_upper.value - operationJsonElevation,
            base: volume.altitude_lower.value - operationJsonElevation,
            altitudeHigher: volume.altitude_upper.value,
            altitudeLower: volume.altitude_lower.value,
            operationType: details.type,
            timeStart: convertZuluToLocalTime(time_start.value),
            timeEnd: convertZuluToLocalTime(time_end.value),
            intent: f.reference.description,
            request: f?.request,
          },
          geometry: volume.outline_polygon,
        };
      });
      return output;
    });
  }

  const getWgs84 = ({ lat, lng }) => {
    return Math.round(
      map.queryTerrainElevation({
        lat,
        lng,
      })
    );
  };
  // console.log("flightdata:", flightData);
  return flightData.flatMap((f) => {
    const { details } = f;
    let { state } = details;
    if (
      focussedOperation &&
      f.reference.id === focussedOperation.reference.id
    ) {
      state = FLIGHT_STATES.FOCUSSED;
      trackerStatusMap.set(f.reference.id, FLIGHT_STATES.FOCUSSED);
    }
    const color = getFlightStateColor(state);

    trackerStatusMap.set(f.reference.id, state);
    trackerOpsMap.set(f.details.platform_tracker_pairs[0].tracker_sn, state);
    // if (isRemoteId) {
    //   trackerStatusMap.set(f.details.platform_tracker_pairs[0], state);
    //   trackerOpsMap.set(f.details.platform_tracker_pairs[0], f);
    // } else {
    //   trackerStatusMap.set(f.reference.id, state);
    //   trackerOpsMap.set(f.reference.id, f);
    // }
    const startTime = f.interuss.operational_intent_reference.time_start.value;
    const endTime = f.interuss.operational_intent_reference.time_end.value;

    let elevation = 0;
    if (
      f.details.conformance_volumes[0].volume.altitude_lower.reference === "W84"
    ) {
      elevation = getWgs84({
        lat: f.request.contingency_plans.landing_point[0][1],
        lng: f.request.contingency_plans.landing_point[0][0],
      });
    }
    // previous render method
    //   return details.operation_volumes.map((v) => {
    //     const { volume } = v;
    //     return {
    //       type: "Feature",
    //       properties: {
    //         id: f.reference.id,
    //         color,
    //         height: volume.altitude_upper.value - elevation,
    //         base: volume.altitude_lower.value - elevation,
    //         operationType: details.type,
    //         timeStart: convertZuluToLocalTime(startTime),
    //         timeEnd: convertZuluToLocalTime(endTime),
    //         intent: f.reference.description,
    //         request: f?.request,
    //       },
    //       geometry: volume.outline_polygon,
    //     };
    //   });
    // });

    // get operation details from first array in operation volumes
    const { volume } = details.operation_volumes[0];
    let output = [];
    let polygonArray = {};
    // check for waypoint
    if (f.details.operation_volumes.length > 1) {
      elevation = getWgs84({
        lat: volume.outline_polygon.coordinates[0][0][1],
        lng: volume.outline_polygon.coordinates[0][0][0],
      });
      // combine multiple volumes into multiple mutipolygons based on base
      // to resolve issue with waypoint operation appearing jagged and start/land polygon not appearing
      f.details.operation_volumes.forEach((singleArray) => {
        if (singleArray.volume.altitude_lower.value in polygonArray) {
          polygonArray[
            singleArray.volume.altitude_lower.value
          ].coordinates.push(singleArray.volume.outline_polygon.coordinates);
        } else {
          polygonArray[singleArray.volume.altitude_lower.value] = {
            coordinates: [[singleArray.volume.outline_polygon.coordinates[0]]],
            type: "MultiPolygon",
          };
        }
      });

      // eslint-disable-next-line guard-for-in
      for (const key in polygonArray) {
        output.push({
          type: "Feature",
          properties: {
            id: f.reference.id,
            color,
            height: volume.altitude_upper.value - elevation,
            base: key - elevation,
            altitudeHigher: volume.altitude_upper.value,
            altitudeLower: volume.altitude_lower.value,
            // height: volume.altitude_upper.value,
            // base: volume.altitude_lower.value,
            operationType: details.type,
            timeStart: convertZuluToLocalTime(startTime),
            timeEnd: convertZuluToLocalTime(endTime),
            intent: f.reference.description,
            request: f?.request,
          },
          geometry: polygonArray[key],
        });
      }
    } else {
      polygonArray = volume.outline_polygon;
      output = {
        type: "Feature",
        properties: {
          id: f.reference.id,
          color,
          height: volume.altitude_upper.value - elevation,
          base: volume.altitude_lower.value - elevation,
          altitudeHigher: volume.altitude_upper.value,
          altitudeLower: volume.altitude_lower.value,
          // height: volume.altitude_upper.value,
          // base: volume.altitude_lower.value,
          operationType: details.type,
          timeStart: convertZuluToLocalTime(startTime),
          timeEnd: convertZuluToLocalTime(endTime),
          intent: f.reference.description,
          request: f?.request,
        },
        geometry: polygonArray,
      };
    }
    return output;
  });
  // });
}

/**
 * Adds data for terrain elevation into Mapbox map
 * @param {*} map Mapbox map
 */
export function addTerrainSource(map, showMapElevation) {
  if (map.getSource("terrain-elevation")) return;
  map.addSource("terrain-elevation", {
    type: "raster-dem",
    tileSize: 512,
    url: "mapbox://mapbox.mapbox-terrain-dem-v1",
  });

  if (showMapElevation) map.setTerrain({ source: "terrain-elevation" });
  if (!showMapElevation) map.setTerrain();

  // Add the terrain layer
  map.addLayer({
    id: "terrain-layer",
    type: "hillshade",
    source: "terrain-elevation",
  });

  setLayerVisibility(map, "terrain-layer", showMapElevation);
}

/**
 * Adds data for operations into Mapbox map
 * @param {*} map Mapbox map
 */
export function addOperationSource(map) {
  if (map.getSource("operation-coords")) return;
  map.addSource("operation-coords", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
}

/**
 * Adds layer displaying operation data into Mapbox map
 * @param {*} map Mapbox map
 */
export function addOperationLayer(map) {
  if (map.getLayer("operation")) return;
  const isDarkMode = localStorage.getItem("color-theme") === '"dark"';
  map.addLayer({
    id: "operation",
    type: "fill-extrusion",
    source: "operation-coords",
    paint: {
      "fill-extrusion-color": ["get", "color"],
      "fill-extrusion-height": ["get", "height"],
      "fill-extrusion-base": ["get", "base"],
      "fill-extrusion-opacity": !isDarkMode ? 0.7 : 0.2,
    },
  });
}

/**
 * Parses tracker data into GEOJson format
 * @param {*} trackerData Tracker data to parse
 * @param {*} modelMap Storage for 3D models
 * @param {*} trackerStatusMap Mapping between operation status and tracker ID
 * @param {*} trackerOpsMap Mapping between ops volume and tracker SN
 * @returns Parsed tracker data
 * only triggers when there is incoming telemetry data
 */

// use flightData to track what flights should be shown? because trackerStatusMap is not being updated correctly

function convertTrackerData(
  flightData,
  trackerData,
  modelMap,
  trackerStatusMap,
  trackerOpsMap,
  api,
  isRemoteId,
  map
) {
  const flightDataMap = flightData.flatMap((item) => item?.reference?.id);
  if (!modelMap.has("ActivatedArrowModel")) return [];

  // if (isRemoteId) {
  // // does not use flightDataMap to filter responses
  // trackerOpsMap will use operation data to disable all trackers in operations
  // telemetry data(named trackerData here) will handle removing unactivated or ended operations
  const output = trackerData.flatMap((data) => {
    return data.puckResponses
      .filter(
        (t) =>
          !t[1].error &&
          trackerOpsMap.has(data?.puckResponses[0][1].extras.tracker_sn)
      )
      .map((t) => {
        const positionLng = t[1]?.position.lng ? t[1]?.position.lng : 0;
        const positionLat = t[1]?.position.lat ? t[1]?.position.lat : 0;
        const altitude =
          map.getTerrain() === null && t[1]?.position.alt > 50
            ? 50
            : t[1]?.position.alt;
        const status = trackerOpsMap.get(
          data?.puckResponses[0][1].extras.tracker_sn
        );

        const isDarkMode = localStorage.getItem("color-theme") === '"dark"';
        const model = isDarkMode
          ? modelMap.get(`${status}ArrowModel`)
          : modelMap.get(`${status}ArrowModelLightMode`);
        const opsVolume = trackerOpsMap.has(
          data?.puckResponses[0][1].extras.tracker_sn
        )
          ? trackerOpsMap.get(data?.puckResponses[0][1].extras.tracker_sn)
          : {};
        const tracks = t[1]?.track;
        return {
          model,
          fixedSize: true,
          maxZoomScale: 0.15,
          scale: [0.0000013, 0.0000013, 0.0000013],
          rotate: [degToRad(90), degToRad(180 + tracks), 0],
          position: {
            lng: positionLng,
            lat: positionLat,
          },
          altitude,
          details: {
            speed: t[1]?.speed.toFixed(1),
            heading: t[1]?.track,
            altitude: t[1]?.position.alt.toFixed(1),
            intent: opsVolume.reference?.description,
            opsVolume,
            platformData: t[2][0]?.data,
            trackerData: t[3][0]?.data,
            position: {
              lng: positionLng.toFixed(7), // 7dp for cm acc (approx only valid near equator)
              lat: positionLat.toFixed(7),
            },
          },
        };
      });
  });
  return Promise.all(output);
  // }

  // // Previous tracker rendering with remoteid and other accounts
  // const output = trackerData.flatMap((data) => {
  //   // console.log("trackerstatusmap", trackerStatusMap);
  //   // console.log("trackerData:", trackerData);

  //   // CHANGED TO filter using flightDataMap
  //   // so tracker symbols are shown and removed correctly without page refresh
  //   return (
  //     data.puckResponses
  //       // check if incoming tracker uuid matches the operation uuid gufi in the Map
  //       .filter((t) => !t[1].error && flightDataMap.includes(data?.gufi))
  //       .map((t) => {
  //         const positionLng = t[1]?.position.lng ? t[1]?.position.lng : 0;
  //         const positionLat = t[1]?.position.lat ? t[1]?.position.lat : 0;
  //         const status = trackerStatusMap.get(data?.gufi);
  //         const altitude =
  //           map.getTerrain() === null && t[1]?.position.alt > 50
  //             ? 50
  //             : t[1]?.position.alt;
  //         const isDarkMode = localStorage.getItem("color-theme") === '"dark"';
  //         const model = isDarkMode
  //           ? modelMap.get(`${status}ArrowModel`)
  //           : modelMap.get(`${status}ArrowModelLightMode`);
  //         const opsVolume = trackerOpsMap.has(data?.gufi)
  //           ? trackerOpsMap.get(data?.gufi)
  //           : {};
  //         const tracks = t[1]?.track;
  //         return {
  //           model,
  //           fixedSize: true,
  //           maxZoomScale: 0.15,
  //           scale: [0.0000013, 0.0000013, 0.0000013],
  //           rotate: [degToRad(90), degToRad(180 + tracks), 0],
  //           position: {
  //             lng: positionLng,
  //             lat: positionLat,
  //           },
  //           altitude,
  //           details: {
  //             speed: t[1]?.speed.toFixed(1),
  //             heading: t[1]?.track,
  //             altitude: t[1]?.position.alt.toFixed(1),
  //             intent: opsVolume.reference?.description,
  //             opsVolume,
  //             platformData: t[2][0]?.data,
  //             trackerData: t[3][0]?.data,
  //             position: {
  //               lng: positionLng.toFixed(7), // 7dp for cm acc (approx only valid near equator)
  //               lat: positionLat.toFixed(7),
  //             },
  //           },
  //         };
  //       })
  //   );
  // });
  // console.log("output:", output);
  // return Promise.all(output);
  // Previous tracker rendering with remoteid and other accounts

  // return Promise.all(
  //   trackerData
  //     .filter((t) => !t.puckResponse.error)
  //     .map(async (t) => {
  //       const positionLng = t.puckResponse.position.lng
  //         ? t.puckResponse.position.lng
  //         : 0;
  //       const positionLat = t.puckResponse.position.lat
  //         ? t.puckResponse.position.lat
  //         : 0;
  //       const status = trackerStatusMap.has(t.gufi)
  //         ? trackerStatusMap.get(t.gufi)
  //         : "";
  //       const model = modelMap.get(`${status}ArrowModel`);
  //       const opsVolume = trackerOpsMap.has(t.gufi)
  //         ? trackerOpsMap.get(t.gufi)
  //         : {};
  //       return {
  //         model,
  //         fixedSize: true,
  //         maxZoomScale: 0.15,
  //         scale: [0.0000013, 0.0000013, 0.0000013],
  //         rotate: [degToRad(90), degToRad(180 + t.puckResponse.track), 0],
  //         position: {
  //           lng: positionLng,
  //           lat: positionLat,
  //         },
  //         altitude: t.puckResponse.position.alt,
  //         details: {
  //           speed: t.puckResponse.speed,
  //           heading: t.puckResponse.track,
  //           altitude: t.puckResponse.position.alt,
  //           intent: opsVolume.reference.description,
  //           opsVolume,
  //         },
  //       };
  //     })
  // );
}

/**
 * Adds layer displaying tracker data onto Mapbox map
 * @param {*} map Mapbox map
 */
export function addTrackerLayer(map) {
  if (map.getLayer("tracker-layer")) return;
  const trackerLayer = new ModelLayer("tracker-layer", convertTrackerData);
  map.addLayer(trackerLayer);
}

// tracks the last time that the rain map was refreshed.
let lastRainMapRefreshDate;

/**
 * Adds rain map source and layer to provided map instance.
 * Note: if layer/source has already been added, this function may remove and read if shown data is over 5 minutes old.
 * @param {mapboxgl.Map} map
 */
export async function addRainMapSourceAndLayer(map, showRainMapLayer) {
  const sourceId = "rain-map-source";
  const layerId = "rain-map-layer";
  const bounds = [
    [103.555, 1.48], // NE
    [104.13, 1.48], // NW
    [104.13, 1.15], // SW
    [103.555, 1.15], // SE
  ];

  if (
    map.getLayer(layerId) == undefined &&
    map.getSource(sourceId) == undefined
  )
    lastRainMapRefreshDate = undefined;

  if (
    lastRainMapRefreshDate === undefined ||
    differenceInMinutes(Date.now(), lastRainMapRefreshDate) > 5
  ) {
    const fetchTime = getCurrentRoundedTime();

    await getLatestRainMapURL(fetchTime).then((res) => {
      if (map.getLayer(layerId)) map.removeLayer(layerId);
      if (map.getSource(sourceId)) map.removeSource(sourceId);
      if (res !== null) {
        const source = {
          type: "image",
          url: res,
          coordinates: bounds,
        };
        map.addSource(sourceId, source);

        const layer = {
          id: layerId,
          type: "raster",
          source: sourceId,
          paint: {
            "raster-opacity": 0.5,
          },
        };
        map.addLayer(layer);
        lastRainMapRefreshDate = fetchTime;
        setLayerVisibility(map, layerId, showRainMapLayer);
      }
    });
  }
  setLayerVisibility(map, layerId, showRainMapLayer);
}

let windMarkersAdded = false;
let currentPopup;

/**
 * Adds all wind speed and direction markers to map instance provided.
 * @param {mapboxgl.Map} map
 */
export async function addWindMarkers(map) {
  if (windMarkersAdded) return;
  windMarkersAdded = true;
  const allStationsData = await fetchWindData();
  for (const [stationName, data] of Object.entries(allStationsData)) {
    const markerHTML = generateMarker(data.speed, data.direction);
    new Marker(markerHTML).setLngLat(data.coordinates).addTo(map);

    markerHTML.addEventListener(
      "click",
      (event) => {
        if (currentPopup) currentPopup.remove();

        const popupHTML = generatePopupContent(
          data.name,
          data.direction,
          data.speed,
          data.time
        );
        const popup = new Popup({
          offset: popupOffsets,
          closeButton: true,
          closeOnClick: true,
          closeOnMove: true,
        })
          .setLngLat(data.coordinates)
          .setHTML(popupHTML)
          .addTo(map);

        currentPopup = popup;
        event.stopPropagation();
      },
      false
    );
  }
}

/**
 * Sets visibility of a given layer in a Mapbox map
 * @param {*} map Mapbox map
 * @param {*} layer Layer to set visibility of
 * @param {Boolean} visible Indicates whether a layer should be shown
 */
export async function setLayerVisibility(map, layer, visible) {
  map.setLayoutProperty(layer, "visibility", visible ? "visible" : "none");
}

export async function addPrecipitationLayer(map, showRainMapLayer) {
  if (map.getLayer("precipitation")) {
    // addPrecipitationLayer is called every 6 seconds
    // handle the updating of layer url
    const date = new Date();
    date.setMinutes(date.getMinutes() - 11);
    const utcTime = date.toISOString().slice(0, 15);
    const tileUrl = map.getSource("precipitationsource").tiles;
    const tileDateTime = (() => {
      if (Array.isArray(tileUrl) && tileUrl.length > 0) {
        const parts = tileUrl[0]?.split("=");
        if (parts.length > 1) {
          return parts[parts.length - 1];
        }
      }
      return "0";
    })();
    if (tileDateTime !== `${utcTime}0`) {
      map.removeLayer("precipitation");
      map.removeSource("precipitationsource");
    } else {
      setLayerVisibility(map, "precipitation", showRainMapLayer);
      return;
    }
  }
  // const openWeatherApiKey =
  //   store.getState().envVar["api_key-openweather"].Value;
  const date = new Date();
  date.setMinutes(date.getMinutes() - 11);
  const utcTime = date.toISOString().slice(0, 15);
  const precipitationsource = {
    type: "raster",
    tiles: [
      // `https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${openWeatherApiKey}`, // add env variable when done testing
      `https://b.sat.owm.io/maps/2.0/radar/{z}/{x}/{y}?appid=9de243494c0b295cca9337e1e96b00e2&day=${utcTime}0`,
    ],
    tileSize: 256,
    bounds: [101, -0.2, 105, 2.6],
    maxzoom: 7,
  };
  map.addSource("precipitationsource", precipitationsource);
  map.addLayer({
    id: "precipitation",
    type: "raster",
    source: "precipitationsource",
    paint: {
      "raster-opacity": 0.5,
    },
    minzoom: 0,
    maxzoom: 22,
  });
  setLayerVisibility(map, "precipitation", showRainMapLayer);

  // Okinawa Weather Layer

  if (!map.getSource("okinawaprecipitation")) {
    map.addSource("okinawaprecipitation", {
      type: "geojson",
      data: okinawa_precipitation,
    });
  }
  // for now there is only 1 source
  // change this to get latest layer from api if accept JMBSC as forecast provider
  if (!map.getLayer("precipitation-heatmap")) {
    map.addLayer({
      id: "precipitation-heatmap",
      type: "fill",
      source: "okinawaprecipitation",
      paint: {
        "fill-color": [
          "interpolate",
          ["linear"],
          ["get", "value"],
          1,
          "#2c7bb6",
          2,
          "#4575b4",
          3,
          "#313695",
        ],
        "fill-opacity": [
          "case",
          ["==", ["get", "value"], 0],
          0, // Make invisible if value is 0
          0.7,
        ],
      },
    });
  }
  setLayerVisibility(map, "precipitation-heatmap", showRainMapLayer);
}
let gnssLayerPreviousState = false;
export async function addGnssLayer(map, showGnssLayer) {
  const mapSource = map.getSource("gnss-coords");
  // only load once use click gnss layer in operations panel
  if (gnssLayerPreviousState === showGnssLayer || showGnssLayer === false) {
    gnssLayerPreviousState = showGnssLayer;
    return;
  }
  gnssLayerPreviousState = showGnssLayer;
  // modify geojson file to existing source
  if (mapSource) {
    const gnssMapFeatures = await getGnsss();

    map.getSource("gnss-coords").setData({
      type: "FeatureCollection",
      features: gnssMapFeatures,
    });
  } else {
    // add geojson file to empty source
    const gnssMapFeatures = await getGnsss();
    if (gnssMapFeatures.length > 0) {
      map.addSource("gnss-coords", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: gnssMapFeatures,
        },
      });
    }
    if (map.getLayer("gnss-layer")) {
      setLayerVisibility(map, "gnss-layer", showGnssLayer);
      return;
    }
    map.addLayer({
      id: "gnss-layer",
      type: "circle",
      source: "gnss-coords",
      paint: {
        "circle-color": [
          "interpolate",
          ["linear"],
          ["get", "weight"],
          0,
          "rgba(0, 255, 0, 0.005)",
          1,
          "rgba(75, 255, 0, 0.005)",
          2,
          "rgba(150, 255, 0, 0.005)",
          3,
          "rgba(225, 255, 0, 0.005)",
          4,
          "rgba(255, 150, 0, 0.005)",
          5,
          "rgba(255, 75, 0, 0.005)",
          6,
          "rgba(255, 0, 0, 0.005)",
        ],
        "circle-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0,
          15,
          1,
          20,
          10,
          21,
          100,
        ],
      },
    });
    setLayerVisibility(map, "gnss-layer", showGnssLayer);
  }
}

export function addJapanDIDSource(map) {
  if (map.getSource("japan-did-coords")) return;
  map.addSource("japan-did-coords", {
    type: "geojson",
    data: japan_did,
  });
}

export async function addJapanDID(map, showSgPopulationLayer) {
  addJapanDIDSource(map);
  if (!map.getLayer("japan-did-layer")) {
    map.addLayer({
      id: "japan-did-layer",
      type: "fill",
      source: "japan-did-coords",
      paint: {
        "fill-opacity": 0.7,
        "fill-color": "#fc8d59",
      },
    });
  }
  setLayerVisibility(map, "japan-did-layer", showSgPopulationLayer);
}

const drawCircleAroundPoint = (geojson, radius = 5) => {
  const coordinates = circle(geojson.geometry.coordinates, radius, {
    units: "kilometers",
  });
  const feature = {
    ...geojson,
    geometry: coordinates.geometry,
  };
  return feature;
};

const getCtrData = async () => {
  const fetchJSON = (url) => {
    return fetch(url).then((response) => response.json());
  };
  const japan_ctr_geojson = await fetchJSON(japan_ctr);
  const featureModified = japan_ctr_geojson.features.map((singleFeature) => {
    return drawCircleAroundPoint(singleFeature);
  });
  return featureModified;
  // return japan_ctr_geojson.features;
};

export async function addJapanCTRSource(map) {
  if (map.getSource("japan-ctr-coords")) return;
  map.addSource("japan-ctr-coords", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  const ctrCoords = await getCtrData();
  map.getSource("japan-ctr-coords").setData({
    type: "FeatureCollection",
    features: ctrCoords,
  });
}

export async function addJapanCTR(map, showStaticConstraintsLayer) {
  addJapanCTRSource(map);
  if (!map.getLayer("japan-ctr-layer")) {
    map.addLayer({
      id: "japan-ctr-layer",
      type: "fill",
      source: "japan-ctr-coords",
      paint: {
        "fill-color": "rgba(0,0,0,0)",
        "fill-outline-color": "#000000",
      },
    });
  }
  setLayerVisibility(map, "japan-ctr-layer", showStaticConstraintsLayer);
}
