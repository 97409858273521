import React, { useState, useEffect } from "react";
import { useApi } from "@/api/useApi";
import { useDispatch } from "react-redux";
import { setOperationFormRequest } from "@/store/actions";
import FlightPlan from "@/model/FlightPlan";
import { OperationJson } from "@/model/api/Operation";
import RerouteRescheduleDialog from "@/components/v1/Dialog/RerouteRescheduleDialog";
import Loader from "@/components/v1/Common/Loader";

interface ProposeOperationDialogProps {
  operationUuid: string;
  isAirspaceManager?: boolean;
  onMenuSelected?(menu: string, isOpening: boolean): void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onCloseModal?: () => void;
  handleProposeChanges?: () => void;
}

export default function ProposeOperationDialog({
  operationUuid,
  isAirspaceManager = false,
  onMenuSelected,
  showMessage,
  onCloseModal,
  handleProposeChanges,
}: ProposeOperationDialogProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [operationData, setOperationData] = useState<OperationJson | null>(
    null
  );
  const [originalRequest, setOriginalRequest] = useState<FlightPlan | null>(
    null
  );
  const [proposedClarification, setProposedClarification] =
    useState<string>("");

  const dispatch = useDispatch();
  const api = useApi();

  const getOperationData = async (operationID: string) => {
    const response = await api.getOperationData({
      operationID,
    });
    return response.data;
  };

  const validateOperation = (operation: OperationJson) => {
    const epochArrivalTime = Date.parse(
      operation.interuss?.operational_intent_reference?.time_end?.value
    );
    if (epochArrivalTime < Date.now()) {
      showMessage && showMessage("Operation has expired ");
      onCloseModal && onCloseModal();
      return false;
    }

    const { original_request } = operation.request;
    if (!original_request && !isAirspaceManager) {
      showMessage && showMessage("Operation has been rejected or has expired ");
      onCloseModal && onCloseModal();
      return false;
    }
    return true;
  };

  const handleEditOpsClick = async (compareData: OperationJson) => {
    // handle two way return trip
    if (compareData?.request?.two_way) {
      const { request } = compareData;
      request.description = compareData?.reference?.description;
      dispatch(
        setOperationFormRequest({
          id: compareData.reference.id,
          type: "edit",
          request,
        })
      );
    } else {
      dispatch(
        setOperationFormRequest({
          id: compareData.reference.id,
          type: "edit",
          request: compareData.request,
        })
      );
    }
    if (isAirspaceManager) {
      handleProposeChanges && handleProposeChanges();
    } else {
      onMenuSelected && onMenuSelected("Flight Authorization", false);
    }
    onCloseModal && onCloseModal();
  };

  const handleOnAccept = async (operationData: OperationJson) => {
    try {
      onCloseModal && onCloseModal();
      showMessage && showMessage("Processing operation, please wait");
      if (operationData.request.two_way) {
        const response: Promise<any>[] = [];
        operationData.reference.mission_list.forEach((singleOperationID) => {
          response.push(api.updateOperation(singleOperationID, "Accepted"));
          response.push(api.aas.setFlightAccepted(singleOperationID));
        });
        await Promise.all(response);
      } else {
        !isAirspaceManager
          ? await api.updateOperation(operationData.reference.id, "Accepted")
          : await api.aas.setFlightAccepted(operationData?.reference?.id);
      }
      showMessage && showMessage("Operation Accepted", true);
    } catch (e: any) {
      let message = "Error Accepting Operation";
      if (e.response?.data?.message) {
        message = e.response.data.message;
      }
      showMessage && showMessage(message, false, true);
    }
    onCloseModal && onCloseModal();
  };

  const handleOnDiscard = async (operationData: OperationJson) => {
    try {
      onCloseModal && onCloseModal();
      showMessage && showMessage("Processing operation, please wait");
      if (operationData.request.two_way) {
        const response: Promise<any>[] = [];
        operationData.reference.mission_list.forEach((singleOperationID) => {
          isAirspaceManager
            ? response.push(api.aas.setFlightRejected(singleOperationID))
            : response.push(api.deleteOperation(singleOperationID));
        });
        await Promise.all(response);
      } else {
        isAirspaceManager
          ? await api.aas.setFlightRejected(operationData.reference.id)
          : await api.deleteOperation(operationData.reference.id);
      }
      showMessage && showMessage("Operation Discarded", true);
    } catch (e: any) {
      let message = "Error Deleting Operation";
      if (e.response?.data?.message) {
        message = e.response.data.message;
      }
      showMessage && showMessage(message, false, true);
    }
  };

  const handleProposeOperation = async () => {
    try {
      const operationDataRes = await getOperationData(operationUuid);
      if (!validateOperation(operationDataRes)) {
        return;
      }
      setOperationData(operationDataRes);
      setOriginalRequest(operationDataRes.request.original_request);
      setProposedClarification(operationDataRes?.request?.clarification);
      setIsLoading(false);
    } catch (e: any) {
      let message = "Error getting operation";
      if (e.response?.data?.message) {
        message = e.response.data.message;
      }
      showMessage && showMessage(message, false, true);
      onCloseModal && onCloseModal();
    }
  };

  useEffect(() => {
    handleProposeOperation();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader isLoading={isLoading} isError={false} />
      ) : (
        <RerouteRescheduleDialog
          isRerouted={false}
          isRescheduled={true}
          isProposeOperation={true}
          isAuthProposeOperation={isAirspaceManager}
          compareData={operationData}
          flightPlan={originalRequest}
          onCloseModal={onCloseModal}
          proposedClarification={
            (!isAirspaceManager && proposedClarification) || ""
          }
          onAcceptChanges={() => operationData && handleOnAccept(operationData)}
          onDiscardPlan={() => operationData && handleOnDiscard(operationData)}
          onEditPlan={() => operationData && handleEditOpsClick(operationData)}
        />
      )}
    </div>
  );
}
