import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import EmbedMapB from "@/components/MapB/embedMapB";
import Loader from "@/components/v1/Common/Loader";
import { useSelector } from "react-redux";

import { OperationJson } from "@/model/api/Operation";
import { useApi } from "../../../api/useApi";

export default function DirectAccess() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [focusedOperationToPassToMap, setfocusedOperationToPassToMap] =
    useState<OperationJson | null>(null);

  const { focusedOperationId } = useParams();

  const api = useApi();

  /* Handle Focus Operation from URL */
  // 1 Get operation ID from URL and set to localstorage
  // 2 During render get ID from localstorage
  // 2a. Delete ID from localstorage to prevent redirect from dashboard
  useEffect(() => {
    // const operationId = searchParams.get("operationId");
    if (!focusedOperationId) return;
    localStorage.setItem(
      "focusedOperationId",
      JSON.stringify(focusedOperationId)
    );
  }, [focusedOperationId]);

  const getOperationInfoFromLocalStorage = async () => {
    const focusOperationLocalStorage =
      localStorage.getItem("focusedOperationId");
    if (!focusOperationLocalStorage) return;
    const focusOperationIdParsed = JSON.parse(focusOperationLocalStorage);
    await getOperationData(focusOperationIdParsed);
  };
  useEffect(() => {
    if (!api) return;
    getOperationInfoFromLocalStorage();
  }, [api]);
  /* End Of Handle Focus Operation from URL */

  const getOperationData = async (operationID: string) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await api.getOperationData({
        operationID,
      });
      setfocusedOperationToPassToMap(response.data);
      setIsLoading(false);

      // localstorage removed too fast causes dashboard to load
      // localStorage.removeItem("focusedOperationId");
    } catch (err) {
      let message = "Unable to retrieve operation data";
      if (axios.isAxiosError(err)) {
        message = err?.response?.data?.message;
      }
      setIsError(true);
      setErrorText(message);
      setIsLoading(false);

      // localstorage removed too fast causes dashboard to load
      // localStorage.removeItem("focusedOperationId");
    }
  };

  // remove direct access localstorage item, prevent redirect to here from dashboard when error
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("focusedOperationId");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      {isLoading || isError ? (
        <Loader
          isLoading={isLoading}
          isError={isError}
          errorText={errorText}
          onRetry={getOperationInfoFromLocalStorage}
        />
      ) : (
        <EmbedMapB
          mapHeight="650px"
          compareData={focusedOperationToPassToMap}
          mapStyle="light"
          isDirectAccess={true}
        />
      )}
    </div>
  );
}
