import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl-style-switcher/styles.css";
import "../custom-mapbox.css";
import "../windmarker/windmarker.css";
import "../windmarker/fonts/fonts.css";

import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { format } from "date-fns";
import { FLIGHT_STATES, HIGHLIGHT_COLORS } from "@/config/flightStatusColor";
import { useApi } from "../../../api/useApi";

// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { getConstraintsFeatures } from "../layers";

/**
 * Component encapsulating MapBox map
 * @param {*} props Props to pass in for the MapB component
 * @returns Component that displays MapBox map
 */
export default function EmbedMapB(props) {
  const {
    mapHeight = document.documentElement.clientHeight - 300, // Reduce map height to accomodate header
    compareData,
    compareCurrentData,
    isWeatherAnalysis = false,
    mapStyle = "dark",
    isDirectAccess = false,
  } = props;
  const api = useApi();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch();
  const [lng, setLng] = useState(103.8167107035549);
  const [lat, setLat] = useState(1.366701902383488);
  const [zoom, setZoom] = useState(13);
  const [modelMap, setModelMap] = useState(new Map());
  const [style, setStyle] = useState(null);
  const [focussedOperation, setFocussedOperation] = useState(null);

  /**
   * Retrieves color to display for Flight state
   * @param {Number} state Flight state
   * @returns Color for flight state
   */
  function getFlightStateColor(state) {
    switch (state) {
      case FLIGHT_STATES.ACTIVATED: {
        return `rgb(${HIGHLIGHT_COLORS.green.slice(0, 3).join(",")})`;
      }
      case FLIGHT_STATES.CONTINGENT: {
        return `rgb(${HIGHLIGHT_COLORS.red.slice(0, 3).join(",")})`;
      }
      case FLIGHT_STATES.NON_CONFORMANT: {
        return `rgb(${HIGHLIGHT_COLORS.orange.slice(0, 3).join(",")})`;
      }
      case FLIGHT_STATES.FOCUSSED: {
        return `rgb(${HIGHLIGHT_COLORS.blue.slice(0, 3).join(",")})`;
      }
      default:
        return "slategrey";
    }
  }

  // // remove direct access localstorage item, prevent redirect to direct access from dashboard
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem("focusedOperationId");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  /**
   * Initialise map with Dark styling with default zoom level 11 and centered at Singapore
   */
  useEffect(() => {
    // mapboxgl.accessToken = store.getState().envVar["api_key-mapbox"].Value;
    // for direct access, mapbox is rendered before parameter store is ready so use env var first
    mapboxgl.accessToken = process.env.REACT_APP_MAPB_TOKEN;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        mapStyle === "dark"
          ? "mapbox://styles/mapbox/dark-v11"
          : "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom,
    });
    /**
     * Add controls onto the current map on loading of site
     */
    map.current.on("load", () => {
      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        }),
        "top-right"
      );
      let constraints = [];
      const styles = [
        {
          title: "Street",
          uri: "mapbox://styles/mapbox/streets-v9",
        },
        {
          title: "Outdoors",
          uri: "mapbox://styles/mapbox/outdoors-v11",
        },
        {
          title: "Light",
          uri: "mapbox://styles/mapbox/light-v10",
        },
        {
          title: "Dark",
          uri: "mapbox://styles/mapbox/dark-v10",
        },
      ];

      map.current.on("styledataloading", () => {
        map.current.once("styledata", (e) => {
          setStyle(e.style);
        });
      });

      map.current.on("click", (e) => {
        const bbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];

        const popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: true,
          closeOnMove: true,
        });

        if (isDirectAccess) {
          const selectedConstraints = map.current.queryRenderedFeatures(bbox, {
            layers: ["constraints"],
          });
          if (selectedConstraints.length) {
            const {
              constraintID,
              timeStart,
              timeEnd,
              base,
              height,
              name,
              desc,
              rule,
              whitelist,
              authReq,
              prohibitReq,
              authWhitelist,
              prohibitWhitelist,
              conditions,
              recurring,
              recurrenceRange,
              altitudeHigher,
              altitudeLower,
            } = selectedConstraints[0].properties;
            const formatDate = (date) =>
              format(new Date(date), "dd/MM/yyyy HH:mm:ss");
            const jsonRules = JSON.parse(rule);
            const rules = Object.keys(jsonRules)
              .filter((k) => jsonRules[k])
              .join(", ")
              .replace("_", " ");

            let constraintsDescription = `
              <table>
                <style>
                  tr.const:nth-of-type(even) {
                    background-color: #ffcccb;
                  }
                </style>
                <tr><td><b>${name}</b></td></tr>
                <tr class="const"><td>CID: ${constraintID}</td></tr>
                <tr class="const"><td>${desc}</td></tr>
                <tr class="const"><td>Alt: ${altitudeLower} to ${altitudeHigher}M</td></tr>
                <tr class="const"><td>Active Window: ${timeStart} to ${timeEnd}</td></tr>
              `;
            let rulesRequired = "";
            if (recurring) {
              const rRangeJson = JSON.parse(recurrenceRange);
              const startTime = formatDate(rRangeJson.time_start.value);
              const endTime = formatDate(rRangeJson.time_end.value);
              constraintsDescription = constraintsDescription.concat(`
                <tr class="const"><td>Recurs ${recurring} from ${startTime} to ${endTime}</td></tr>
                `);
            }
            if (jsonRules.prohibited && prohibitReq) {
              const prohibitReqJSON = JSON.parse(prohibitReq);
              let startTime;
              if (prohibitReqJSON?.time_start?.value) {
                startTime = formatDate(prohibitReqJSON.time_start.value);
              }
              let endTime;
              if (prohibitReqJSON?.time_end?.value) {
                endTime = formatDate(prohibitReqJSON.time_end.value);
              }
              const whitelistJSON =
                prohibitWhitelist && JSON.parse(prohibitWhitelist);
              if (whitelistJSON?.users?.length) {
                rulesRequired = rulesRequired.concat(`
                  ${
                    whitelistJSON.users.length > 3
                      ? `Whitelisted Users: ${whitelistJSON.users
                          .slice(0, 3)
                          .join(", ")}... +${
                          whitelistJSON.users.length - 3
                        } more. `
                      : `Whitelisted Users: ${whitelistJSON.users
                          .slice(0, 3)
                          .join(", ")}. `
                  }
                  `);
              }
              if (whitelistJSON?.usergroups?.length) {
                rulesRequired = rulesRequired.concat(`
                  ${
                    whitelistJSON.usergroups.length > 3
                      ? `Whitelisted Groups: ${whitelistJSON.usergroups
                          .slice(0, 3)
                          .join(", ")}... +${
                          whitelistJSON.usergroups.length - 3
                        } more. `
                      : `Whitelisted Groups: ${whitelistJSON.usergroups
                          .slice(0, 3)
                          .join(", ")}. `
                  }
                  `);
              }

              if ("recurring" in prohibitReqJSON) {
                const rRangeJson = prohibitReqJSON.recurrence_range;
                startTime = formatDate(rRangeJson.time_start.value);
                endTime = formatDate(rRangeJson.time_end.value);
                const rStartTime = formatDate(prohibitReqJSON.time_start.value);
                const rEndTime = formatDate(prohibitReqJSON.time_end.value);
                rulesRequired = rulesRequired.concat(`
                  Prohibited from ${startTime} to ${endTime}. <br>
                  `);
                rulesRequired = rulesRequired.concat(`
                  Recurs ${prohibitReqJSON.recurring} from ${rStartTime} to ${rEndTime}<br>
                  `);
              } else if (startTime && endTime) {
                rulesRequired = rulesRequired.concat(`
                Prohibited from ${startTime} to ${endTime}. <br>
                `);
              }
            }
            if (jsonRules.authorisation_required) {
              const authReqJSON = JSON.parse(authReq);
              let startTime;
              if (authReqJSON?.time_start?.value) {
                startTime = formatDate(authReqJSON.time_start.value);
              }
              let endTime;
              if (authReqJSON?.time_end?.value) {
                endTime = formatDate(authReqJSON.time_end.value);
              }
              const whitelistJSON = authWhitelist && JSON.parse(authWhitelist);
              if (whitelistJSON?.users?.length) {
                rulesRequired = rulesRequired.concat(`
                  ${
                    whitelistJSON.users.length > 3
                      ? `Whitelisted Users: ${whitelistJSON.users
                          .slice(0, 3)
                          .join(", ")}... +${
                          whitelistJSON.users.length - 3
                        } more. `
                      : `Whitelisted Users: ${whitelistJSON.users
                          .slice(0, 3)
                          .join(", ")}. `
                  }
                  `);
              }
              if (whitelistJSON?.usergroups?.length) {
                rulesRequired = rulesRequired.concat(`
                  ${
                    whitelistJSON.usergroups.length > 3
                      ? `Whitelisted Groups: ${whitelistJSON.usergroups
                          .slice(0, 3)
                          .join(", ")}... +${
                          whitelistJSON.usergroups.length - 3
                        } more. `
                      : `Whitelisted Groups: ${whitelistJSON.usergroups
                          .slice(0, 3)
                          .join(", ")}.<br>`
                  }
                  `);
              }

              if ("recurring" in authReqJSON) {
                const rRangeJson = authReqJSON.recurrence_range;
                startTime = formatDate(rRangeJson.time_start.value);
                endTime = formatDate(rRangeJson.time_end.value);
                const rStartTime = formatDate(authReqJSON.time_start.value);
                const rEndTime = formatDate(authReqJSON.time_end.value);
                rulesRequired = rulesRequired.concat(`
                  Authorisation required from ${startTime} to ${endTime}.<br>
                  `);
                rulesRequired = rulesRequired.concat(`
                    Recurs ${authReqJSON.recurring} from ${rStartTime} to ${rEndTime}<br>
                    `);
              } else if (startTime && endTime) {
                rulesRequired = rulesRequired.concat(`
                Authorisation required from ${startTime} to ${endTime}.<br>
            `);
              }
            }
            if (jsonRules.conditional) {
              const condJson = JSON.parse(conditions);
              rulesRequired = rulesRequired.concat(`
                ${condJson.no_camera ? "No camera allowed. " : ""}
                ${condJson.power_tethered ? "Tethering required. " : ""}
                ${
                  condJson.noise_threshold
                    ? `Noise below ${condJson.noise_threshold} db. `
                    : ""
                }
                ${condJson.others ? `${condJson.others}. ` : ""}
                `);
            }
            if (!jsonRules.information_only) {
              constraintsDescription = constraintsDescription.concat(`
                <tr class="const"><td>Rules: ${rules}</td></tr>
                <tr class="const"><td>${rulesRequired}</td></tr>
                `);
            }
            constraintsDescription = constraintsDescription.concat(`</table>`);

            popup
              .setHTML(constraintsDescription)
              .setLngLat(e.lngLat)
              .addTo(map.current);
          }
        }
      });

      map.current.on("click", "outline", (e) => {
        if (!isWeatherAnalysis) return;
        let weatherAnalysis;
        try {
          weatherAnalysis = JSON.parse(e.features[0].properties.weather);
        } catch {
          weatherAnalysis = [];
        }
        let popupHTML = `<table>
        <style>
          tr.const:nth-of-type(even) {
            background-color: #ffcccb;
          }
        </style>
        <tr><td><b>Flight Time : ${e.features[0].properties.time}</b></td></tr>
        <tr class="const"><td><strong>Status:</strong> ${e.features[0].properties.status}</td></tr>
        <tr class="const"><td><strong>EAS :</strong> ${e.features[0].properties.speed_eas}</td></tr>
        <tr class="const"><td><strong>GS :</strong> ${e.features[0].properties.speed_gs}</td></tr>`;

        weatherAnalysis.map((singleWeatherData) => {
          if (!singleWeatherData) return;
          popupHTML += `
            <tr class="const"><td><strong>${singleWeatherData?.name} status :</strong> ${singleWeatherData.status}</td></tr>
            <tr class="const"><td><strong>${singleWeatherData?.name} :</strong> ${singleWeatherData?.value} ${singleWeatherData.unit}</td></tr>
          `;
        });
        popupHTML += "</table>";

        new mapboxgl.Popup()
          .setLngLat(e.lngLat) // Use the location of the click
          .setHTML(popupHTML)
          .addTo(map.current);
      });

      // Change cursor to pointer when hovering over the line
      map.current.on("mouseenter", "outline", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Reset cursor to default when no longer hovering
      map.current.on("mouseleave", "outline", () => {
        map.current.getCanvas().style.cursor = "";
      });

      const addCurrentCompareLayer = async () => {
        if (!map.current || !compareCurrentData) return;
        if (isWeatherAnalysis) {
          let previousSegment;
          const getSegmentColor = (status) => {
            if (status === "severe") {
              return "#FF0000";
            }
            if (status === "moderate") {
              return "#FFFF00";
            }
            return "#00FF00";
          };
          const geoJsonData = {
            type: "FeatureCollection",
            features: compareCurrentData.flatMap((segment) => {
              const coordinates = previousSegment
                ? [
                    previousSegment,
                    [segment.longitude, segment.latitude, segment.altitude],
                  ]
                : [[segment.longitude, segment.latitude, segment.altitude]];

              const lineFeature = {
                type: "Feature",
                properties: {
                  status: segment.status,
                  time: moment(segment.timeframe).format("hh:mm a"),
                  speed_gs: `${segment.speed_gs.value} ${segment.speed_gs.unit}`,
                  speed_eas: `${segment.speed_eas.value} ${segment.speed_eas.unit}`,
                  weather: segment.weather_parameter,
                  color: getSegmentColor(segment.status),
                },
                geometry: {
                  type: "LineString",
                  coordinates,
                },
              };

              const pointFeature = {
                type: "Feature",
                properties: {
                  description: "Point",
                  color: "#000000",
                },
                geometry: {
                  type: "Point",
                  coordinates: [
                    segment.longitude,
                    segment.latitude,
                    segment.altitude,
                  ],
                },
              };

              previousSegment = [
                segment.longitude,
                segment.latitude,
                segment.altitude,
              ];

              return [lineFeature, pointFeature];
            }),
          };
          map.current.addSource("comparecurrent", {
            type: "geojson",
            data: geoJsonData,
          });

          map.current.addLayer({
            id: "outline",
            type: "line",
            source: "comparecurrent",
            layout: { "line-join": "round", "line-cap": "round" },
            paint: {
              "line-color": ["get", "color"],
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                5,
                5,
                10,
                10,
                15,
                10,
              ],
            },
          });
          map.current.addLayer({
            id: "point-layer",
            type: "circle",
            source: "comparecurrent",
            paint: {
              "circle-color": "#000000",
              "circle-radius": 3,
            },
          });
        } else {
          const dataCoordinate = compareCurrentData.area_coordinates
            ? compareCurrentData.area_coordinates
            : compareCurrentData.waypoints;
          map.current.addSource("comparecurrent", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: dataCoordinate,
              },
            },
          });

          map.current.addLayer({
            id: "outline",
            type: "line",
            source: "comparecurrent",
            layout: { "line-join": "round", "line-cap": "round" },
            paint: {
              "line-color": "#0080ff",
              "line-width": 3,
            },
          });
        }
      };
      const addCompareLayer = async () => {
        if (!map.current || !compareData) return;
        const { state } = compareData.details;
        const color = getFlightStateColor(state);
        const operationS = compareData.details.operation_volumes.map(function (
          d
        ) {
          return {
            type: "Feature",
            properties: {
              color,
              height: d.volume.altitude_upper.value,
              base: d.volume.altitude_lower.value,
            },
            geometry: d.volume.outline_polygon,
          };
        });
        map.current.addSource("comparecurrent-polygon", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: operationS,
          },
        });

        // Add a new layer to visualize the polygon.
        map.current.addLayer({
          id: "comparePolygon",
          type: "fill-extrusion",
          source: "comparecurrent-polygon", // reference the data source
          paint: {
            "fill-extrusion-color": isDirectAccess ? ["get", "color"] : "grey",
            "fill-extrusion-height": ["get", "height"],
            "fill-extrusion-base": ["get", "base"],
            "fill-extrusion-opacity": 0.5,
          },
        });

        map.current.flyTo({
          center:
            compareData.details.operation_volumes[0].volume.outline_polygon
              .coordinates[0][0],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      };
      const updateConstraintsLayer = async () => {
        try {
          const res = await api.getConstraints();
          constraints = res.data;
        } catch (err) {
          console.log(`Error in getting constraints.${err}`);
          if (
            err?.response?.data?.message ===
            "No active constraints can be retrieved."
          )
            return;
        }
        map.current.addSource("constraints-coords", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: constraints,
          },
        });
        const constraintSource = map.current.getSource("constraints-coords");
        if (!constraintSource) return;
        constraintSource.setData({
          type: "FeatureCollection",
          features: getConstraintsFeatures(constraints, focussedOperation),
        });
        map.current.addLayer({
          id: "constraints",
          type: "fill-extrusion",
          source: "constraints-coords", // reference the data source
          paint: {
            "fill-extrusion-color": "red",
            "fill-extrusion-height": ["get", "height"],
            "fill-extrusion-base": ["get", "base"],
            "fill-extrusion-opacity": 0.2,
          },
        });
      };
      updateConstraintsLayer();
      addCurrentCompareLayer();
      addCompareLayer();

      if (isWeatherAnalysis) {
        map.current.flyTo({
          center: [
            compareCurrentData[0].longitude,
            compareCurrentData[0].latitude,
          ],
          zoom: 15,
          essential: true,
        });
      }
    });
  }, [compareCurrentData, compareData, focussedOperation]);

  return (
    <LoadingOverlay>
      <div ref={mapContainer} style={{ height: mapHeight, width: "100%" }} />
    </LoadingOverlay>
  );
}
